@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Bebas Neue', sans-serif;
}
.text-custom {
    font-family: 'Courier New', Courier, monospace;
    color: white;
}
.image-pixelated {
    image-rendering: pixelated; /* Mantiene el estilo de pixel art */
    image-rendering: crisp-edges; /* Alternativa para navegadores que no soportan pixelated */
  }
